@import '../../../../variables';

.MyAccountSectionHeader {
  background-color: $black;
  border-radius: 5px;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Title {
    font-weight: bold;
    color: $white;
  }

  .Action {
    color: $white;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}