@import '../../variables';

.UserActions {
  display: none;
  cursor: pointer;
  color: $dark-blue;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center; 

  @media (min-width: $breakpoint-tablet) {
    display: flex;
  }

  .UserAction {
    text-transform: uppercase;

    &:hover {
      color: $brown;
    } 
  } 
} 
