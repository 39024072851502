@import '../../variables';

.AlphabeticNavigation {
  display: flex;
  justify-content: center; 
  color: $white; 
  flex-wrap: wrap; 
  border-radius: 5px;
  margin-bottom: 25px;
  background-color: $black;
  font-size: 90%;

  .Letter {
    display: inline-block;
    margin: 0 5px;
    padding: 5px 0;
    cursor: pointer; 
 
    @media (max-width: $breakpoint-mobile) {
      padding: .4em;
    }
  }
} 
